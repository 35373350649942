import { AnimatePresence, motion } from 'framer-motion';
import { border, space, system } from 'styled-system';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from '../grid';
import { Icon } from '../icons';
import { Label, P } from '../typography';

const borderImages = system({
  borderImage: true,
});

const Wrapper = styled(Flex)`
  ${border};
  ${borderImages};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-top-width: 0px;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};

  &:last-child {
    border-bottom-width: 0px;
  }
`;

const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 1rem;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.colors.black : theme.colors.grey300)};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.black : 'transparent')};
  transition: 200ms background-color, border-color;
`;

const Radio = styled(Checkbox)`
  border-radius: 12px;
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.grey300};
  ${space};

  &:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: ${({ selected, theme }) => (selected ? theme.colors.black : 'transparent')};
    transition: 200ms background-color;
  }
`;

const InputListItem = ({
  borderBottomColor,
  borderBottomStyle,
  borderBottomWidth,
  borderImage,
  children,
  disabled,
  label,
  labelProps,
  onSelect,
  py,
  selected,
  selectedHelperText,
  subItems,
  type,
}) => (
  <Wrapper
    borderBottomColor={borderBottomColor}
    borderBottomStyle={borderBottomStyle}
    borderBottomWidth={borderBottomWidth}
    borderImage={borderImage}
    disabled={disabled}
    justifyContent="space-between"
    onClick={disabled ? noop : onSelect}
    py={py}
  >
    <Flex flexDirection="column">
      <Flex flexDirection="row" flexGrow={1} alignItems="center">
        <Flex>
          {type === 'radio' && <Radio selected={selected} />}
          {type === 'checkbox' && (
            <Checkbox selected={selected}>{selected && <Icon name="tick" fill="white" />}</Checkbox>
          )}
        </Flex>

        <Flex flexDirection="column" flexGrow={1}>
          <Flex alignItems="center">
            <Label {...labelProps}>{label}</Label>
          </Flex>
          {subItems}
        </Flex>
      </Flex>
      <AnimatePresence initial={false}>
        {selectedHelperText && selected && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: {
                height: 'auto',
                opacity: 1,
                transition: {
                  type: 'tween',
                  mass: 0.5,
                  bounce: 0,
                },
              },
              hidden: {
                height: 0,
                opacity: 0,
                transition: {
                  type: 'tween',
                  mass: 0.5,
                  bounce: 0,
                },
              },
            }}
          >
            <P ml="2.5rem" pt="0.25rem" pr="1rem" variant={4}>
              {selectedHelperText}
            </P>
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>

    {children}
  </Wrapper>
);

InputListItem.propTypes = {
  borderBottomColor: PropTypes.string,
  borderBottomStyle: PropTypes.string,
  borderBottomWidth: PropTypes.string,
  borderImage: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.shape({}),
  onSelect: PropTypes.func.isRequired,
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.bool,
  selectedHelperText: PropTypes.string,
  subItems: PropTypes.node,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
};

InputListItem.defaultProps = {
  borderBottomColor: 'background',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderImage: undefined,
  children: null,
  disabled: false,
  labelProps: undefined,
  py: '0.75rem',
  selected: false,
  selectedHelperText: undefined,
  subItems: null,
};

export { InputListItem, Radio };
