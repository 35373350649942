import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { P } from '../../typography';

const OrderSettingListItem = ({ label, subtitle, selected, icon, onSelect, children, disabled }) => (
  <Wrapper
    alignItems="center"
    disabled={disabled}
    justifyContent="space-between"
    onClick={onSelect}
    p={{ _: 1, md: '0.5rem' }}
  >
    <Flex>
      {icon && (
        <Box mr={1}>
          <Icon name={icon} fill={!disabled && selected ? 'black' : 'greyPrimary'} />
        </Box>
      )}
      <div>
        <P
          fontWeight={!disabled && selected ? 'bold' : 'none'}
          color={!disabled && selected ? 'black' : 'greyPrimary'}
          mb={{ xs: '0.25rem', md: 0 }}
        >
          {label}
        </P>
        {subtitle && (
          <P variant={3} color={!disabled && selected ? 'black' : 'greyPrimary'}>
            {subtitle}
          </P>
        )}
      </div>
    </Flex>
    {children}
    {selected && !disabled && <Icon name="tick" fill="successPrimary" />}
  </Wrapper>
);

const Wrapper = styled(Flex)`
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  }
`;

OrderSettingListItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
};

OrderSettingListItem.defaultProps = {
  children: null,
  icon: null,
  subtitle: null,
};

export { OrderSettingListItem };
