import PropTypes from 'prop-types';

import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { P } from '../../typography';
import { Radio } from '../../input-list-item';

const PaymentListItem = ({ label, selected, iconName, onSelect, children, iconWidth, iconHeight }) => (
  <Box alignItems="center" mb={1}>
    <button type="button" onClick={onSelect}>
      <Flex alignItems="center">
        <Flex mr="14px">
          <Radio selected={selected} mr={0} />
        </Flex>
        <Flex width="34px" justifyContent="center">
          <Icon name={iconName} width={iconWidth} height={iconHeight} />
        </Flex>
        <P ml="8px">{label}</P>
      </Flex>
    </button>
    {selected && <Box mt="0.5rem">{children}</Box>}
  </Box>
);

PaymentListItem.propTypes = {
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  children: PropTypes.node,
};

PaymentListItem.defaultProps = {
  children: null,
  iconWidth: '24px',
  iconHeight: '24px',
};

export { PaymentListItem };
