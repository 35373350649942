import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { Button } from '../button';
import { Icon } from '../icons';
import { P } from '../typography';

const StyledButton = styled(Button)`
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
    border-color: ${theme.colors.neonTeal400};
    background-color: ${theme.colors.neonTeal100};
    color: ${theme.colors.neonTeal800};
    fill: ${theme.colors.neonTeal800};
  `}
`;

const SelectableButton = ({ iconName, onClick, isSelected, isDisabled, children, ...rest }) => (
  <StyledButton
    disabled={isDisabled}
    $isSelected={isSelected}
    width="100%"
    variant="selectable"
    onClick={onClick}
    {...rest}
    px={0}
  >
    <Flex flexDirection={{ _: 'column', md: 'row' }} alignItems="center">
      {iconName && (
        <Box mr={{ _: 0, md: '0.5rem' }} mb={{ _: '0.5rem', md: 0 }}>
          <Icon name={iconName} />
        </Box>
      )}
      <P textTransform="none" variant={1}>
        {children}
      </P>
    </Flex>
  </StyledButton>
);

SelectableButton.propTypes = {
  iconName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
};

SelectableButton.defaultProps = {
  iconName: null,
  isSelected: false,
  isDisabled: false,
  children: null,
};

export { SelectableButton };
