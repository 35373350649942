import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { A, P } from '../typography';
import { Col, Row } from '../grid';
import { Image } from '../image';
import { InputListItem } from '../input-list-item';
import { OrderSettingListItem } from './order-setting-list-item';
import { PaymentListItem } from './payment-list-item';
import { SelectableButton } from '../selectable-button';

const createOnRadioPress = ({ item, onSelect }) => () => onSelect([item]);

const createOnCheckboxPress = ({ item, items, onSelect, selected, selectedItems }) => {
  const onCheckboxPress = () => {
    // If item is currently selected, remove the value, otherwise add the value (to the values array).
    const newValues = selected ? selectedItems.filter(value => value !== item.value) : selectedItems.concat(item.value);

    // Create an array of items matching the newValues array.
    const consolidatedSelect = newValues.map(value => items.find(iteratedItem => value === iteratedItem.value));
    onSelect(consolidatedSelect);
  };
  return onCheckboxPress;
};

const InputList = ({ items, type, selectedItems, onSelect, children, showClearFilter }) => {
  const { DietaryPreferencesStore } = useRootStore();

  const aspectRatio = useMediaQuery({ query: '(max-width: 390px)' }) ? 1 : undefined;

  if (type === 'tile') {
    return (
      <Row>
        {items.map(item => (
          <Col width={1 / 3} key={item.value}>
            <SelectableButton
              isSelected={!!selectedItems.find(selected => selected === item.value)}
              iconName={item.icon}
              onClick={() => onSelect(item.value)}
              {...item}
            >
              {item.label}
            </SelectableButton>
          </Col>
        ))}
      </Row>
    );
  }

  if (type === 'orderType') {
    return (
      <>
        {items.map(({ disabled = false, icon, label, subtitle, value }) => (
          <OrderSettingListItem
            key={value}
            label={label}
            icon={icon}
            selected={!!selectedItems.find(selected => selected === value)}
            onSelect={() => onSelect(value)}
            disabled={disabled}
            subtitle={subtitle}
          />
        ))}
      </>
    );
  }

  if (type === 'payment') {
    return (
      <>
        {items.map(item => (
          <PaymentListItem
            key={item.value}
            selected={!!selectedItems.find(selected => selected === item.value)}
            onSelect={() => onSelect(item.value)}
            {...item}
          />
        ))}
      </>
    );
  }

  return (
    <div data-testid="input-list">
      {showClearFilter && (
        <P variant={4} mb="0.5rem">
          Showing dietary preferences
          <A variant={4} ml="0.5rem" onClick={() => DietaryPreferencesStore.clearDietaryPreferences()}>
            Clear filter
          </A>
        </P>
      )}
      {items.map(item => {
        const key = item.value;
        const selected = Boolean(selectedItems.find(value => value === key));

        const handleOnSelect =
          type === 'radio'
            ? createOnRadioPress({ item, onSelect })
            : createOnCheckboxPress({ item, items, onSelect, selected, selectedItems });

        return (
          <InputListItem
            key={key}
            label={item.label}
            icon={item.icon}
            selected={!!selectedItems.find(select => select === item.value)}
            type={type}
            disabled={item.disabled}
            onSelect={handleOnSelect}
            borderBottomWidth="0px"
          >
            <>
              {item.disabled && (
                <P ml={1} variant={3} flex="none">
                  Currently Unavailable
                </P>
              )}
              {item.text && !item.disabled && (
                <P ml={1} variant={3}>
                  {item.text}
                </P>
              )}
              {item.image && !item.disabled && (
                <Image maxHeight="70px" my="10px" aspectRatio={aspectRatio} src={item.image} />
              )}
              {children}
            </>
          </InputListItem>
        );
      })}
    </div>
  );
};

InputList.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      subtitle: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox', 'orderType', 'payment', 'tile', 'basting']).isRequired,
  showClearFilter: PropTypes.bool,
};

InputList.defaultProps = {
  id: undefined,
  children: null,
  showClearFilter: false,
};

export { InputList };
